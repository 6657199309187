<template>
    <div class="mb-2">
        <h4 class="mb-2">{{ $t('personalId.uploadsHeading') }}</h4>
        <div class="bg-white p-3 rounded-sm shadow">
            <p>{{ $t('personalId.uploadsText') }}</p>
            <div class="row mt-4">
                <div class="col-12 col-lg-6">
                    <strong>{{ $t('personalId.chooseFiles')}}</strong>
                    <uploader-box
                        :multi="true"
                        :url="$api.dataCheckup.uploaderUrl"
                        class="mt-3"
                        @uploaded="onUploadUploaded"
                    />
                </div>
                <div class="col-12 col-lg-6">
                    <strong>{{ $t('personalId.listUploadedHeading') }}</strong>
                    <p class="alert alert-primary mt-3" v-if="$v.uploads.$invalid">{{ $t('dataCheckup.noDocuments')}}</p>
                    <loader-overlay
                        v-if="uploads.length > 0"
                        :loading="loadingUploads"
                        class="mt-3"
                    >
                        <uploads-list
                            :uploads="uploads"
                            :removed="[]"
                            @remove="onUploadRemove"
                        />
                    </loader-overlay>
                </div>
            </div>
            <p class="mt-4">
                <b-btn
                    @click.prevent="onCloseUploader"
                    size="sm"
                    class="mr-2"
                >
                    {{ $t('app.cancel') }}
                </b-btn>
            </p>
        </div>
    </div>
</template>
<script>
import UploaderBox from '../../../../../Components/UploaderBox.vue'
import UploadsList from '../../../../../Components/UploadsList.vue'
import { requiredIf } from 'vuelidate/lib/validators'

export default {
    name: 'Uploader',
    components: {
        UploaderBox,
        UploadsList
    },
    props: {
        showUploader: {
            type: Boolean,
            required: true
        }
    },
    data () {
        return {
            uploads: [],
            loadingUploads: true
        }
    },
    mounted () {
        this.$nextTick(() => {
            this.loadUploads()
        })
    },
    methods: {
        async onUploadRemove (upload) {
            try {
                await this.$api.dataCheckup.deleteUploadedFile(upload._id)
                this.loadUploads()
            } catch (error) {
                console.error(error)
            }
        },
        async loadUploads () {
            try {
                this.loadingUploads = true
                const response = await this.$api.dataCheckup.listUploadedFiles()
                this.uploads = response.data
            } catch (error) {
                console.error(error)
            } finally {
                this.$nextTick(() => {
                    this.loadingUploads = false
                })
            }
        },
        onUploadUploaded (data) {
            const serverData = JSON.parse(data.serverId)
            if (Array.isArray(serverData) && serverData.length > 0) {
                this.$nextTick(() => {
                    this.loadUploads()
                })
            }
        },
        onCloseUploader () {
            this.$emit('close')
        }
    },
    validations: {
        uploads: {
            required: requiredIf(function (model) {
                return !this.showUploader
            }),
            custom (value) {
                return Array.isArray(value) && value.length > 0
            }
        }
    }
}
</script>
