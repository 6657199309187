<template>
    <div class="my-4">
        <component-loader v-if="enumsLoading || clientDataLoading"/>
        <div v-else>
            <h1>{{ $t('dataCheckup.heading') }}</h1>
            <b-form @submit.prevent="onSubmit" class="bg-white shadow p-4 rounded">
                <basic-data
                    :form-data="formData"
                    :schema="schema.schema"
                    :edited-form-data="editedFormData"
                    @submit="onSubmitSchemaForm"
                    @is-valid="onFormValid"
                />
                <company-people
                    v-if="clientData.companyPeople && Array.isArray(clientData.companyPeople) && clientData.companyPeople.length > 0"
                    class="mt-4"
                    ref="companyPeopleRef"
                    :form-data="formData"
                    :schema="schema.schema"
                    :edited-form-data="editedFormData"
                    @submit="onSubmitSchemaForm"
                    @is-valid="onFormValid"
                />
                <real-owners
                    v-if="clientData.realOwners && Array.isArray(clientData.realOwners) && clientData.realOwners.length > 0"
                    class="mt-4"
                    :form-data="formData"
                    :schema="schema.schema"
                    :edited-form-data="editedFormData"
                    @submit="onSubmitSchemaForm"
                />
                <div class="mt-4">
                    <div v-if="!showUploader">
                        <p class="alert alert-primary">
                            {{ $t('dataCheckup.uploadNewFiles') }}
                        </p>
                        <p>
                            <b-btn
                                variant="primary"
                                size="sm"
                                @click.prevent="onShowUploader"
                            >
                                {{ $t('app.uploadNewFiles') }}
                            </b-btn>
                        </p>
                    </div>
                    <div v-if="showUploader" class="p-3 bg-cyrrus-gray-light border rounded-sm">
                        <uploader
                            :show-uploader="showUploader"
                            @close="onCloseUploader"
                        />
                    </div>
                </div>
                <form-input-textarea
                    class="mt-4"
                    :label="$t('dataCheckup.otherChanges')"
                    v-model="otherChangesComputed"
                    :placeholder="$t('dataCheckup.otherChangesPlaceholder')"
                />
                <p class="mt-5">
                    <b-btn
                        type="submit"
                        variant="primary"
                        :disabled="!isFormValid"
                    >
                        {{ $t('app.submit') }}
                    </b-btn>
                </p>
            </b-form>
        </div>
    </div>
</template>
<script>
import flat from 'flat'
import { mapGetters } from 'vuex'
import { Schema } from '../Components/Schema'
import BasicData from '../Components/BasicData.vue'
import CompanyPeople from '../Components/CompanyPeople.vue'
import RealOwners from '../Components/RealOwners.vue'
import Uploader from '../Components/Uploader.vue'

let tm

export default {
    name: 'DataCheckup',
    components: {
        BasicData,
        CompanyPeople,
        RealOwners,
        Uploader
    },
    data () {
        return {
            clientData: null,
            editedFormData: null,
            enumsLoading: true,
            clientDataLoading: true,
            showUploader: false,
            otherChanges: window.sessionStorage.getItem('otherChanges') || '',
            isFormValid: true
        }
    },
    computed: {
        ...mapGetters([
            'countries',
            'personalIdTypes',
            'genders',
            'riskActivities',
            'statutoryTypes'
        ]),
        otherChangesComputed: {
            get () {
                return this.otherChanges
            },
            set (value) {
                clearTimeout(tm)
                tm = setTimeout(() => { window.sessionStorage.setItem('otherChanges', value) }, 500)
                this.otherChanges = value
            }
        },
        schema () {
            return new Schema(
                this.clientData,
                {
                    countries: this.countries,
                    personalIdTypes: this.personalIdTypes,
                    genders: this.genders,
                    riskActivities: this.riskActivities,
                    statutoryTypes: this.statutoryTypes
                }
            )
        },
        formData () {
            return flat.flatten(this.clientData)
        }
    },
    mounted () {
        this.loadEnums()
        this.loadData()
            .then(() => {
                this.$nextTick(() => {
                    this.getEditedFormData()
                    this.checkValidity()
                })
            })
    },
    methods: {
        onFormValid (isValid) {
            this.isFormValid = isValid
        },
        checkValidity () {
            for (const [index, companyPerson] of (this.clientData?.companyPeople ?? []).entries()) {
                if (new Date(companyPerson.documentValidity) < new Date()) {
                    this.isFormValid = false
                    this.$refs.companyPeopleRef.onEditPerson(index)
                }
            }
        },
        getEditedFormData () {
            const tmp = {}
            for (const key of Object.keys(this.formData)) {
                const sessionStorageValue = window.sessionStorage.getItem(`${key}`)
                if (sessionStorageValue) {
                    tmp[`${key}`] = sessionStorageValue
                }
            }
            this.editedFormData = { ...tmp }
        },
        async loadData () {
            try {
                this.clientDataLoading = true
                const response = await this.$api.dataCheckup.read()
                this.clientData = response.data
            } catch (error) {
                console.error(error)
                this.$notify.error('Nepodařilo se načíst sezení.')
            } finally {
                this.$nextTick(() => {
                    this.clientDataLoading = false
                })
            }
        },
        loadEnums () {
            const enums = [
                { name: 'countries', id: 'countries' },
                { name: 'personalIdTypes', id: 'personal-id-types' },
                { name: 'genders', id: 'genders' },
                { name: 'riskActivities', id: 'risk-activities' },
                { name: 'statutoryTypes', id: 'statutory-types' }
            ]
            this.enumsLoading = true
            Promise.all(enums.map(async (item) => {
                const response = await this.$api.enums.read(item.id)
                return {
                    enum: item.name,
                    content: [...response.data]
                }
            })).then((lists) => {
                for (const list of lists) {
                    this.$store.commit('setEnum', list)
                }
            }).catch((error) => {
                console.error(error)
            }).finally(() => {
                this.$nextTick(() => {
                    this.enumsLoading = false
                })
            })
        },
        onSubmitSchemaForm (data) {
            this.editedFormData = { ...this.editedFormData, ...data }
        },
        onShowUploader () {
            this.showUploader = true
        },
        onCloseUploader () {
            this.showUploader = false
        },
        async onSubmit () {
            const changedClientData = []
            for (const key of Object.keys(this.editedFormData)) {
                if (`${this.editedFormData[key]}` !== `${this.formData[key]}`) {
                    const foundSchema = this.schema.schema.find(item => item.path === key)
                    const label = foundSchema && foundSchema.label ? foundSchema.label : key
                    changedClientData.push({ key, newValue: this.editedFormData[key], friendlyName: this.$t(label, { locale: 'cs' }) })
                }
            }
            const updatedData = {
                changedClientData,
                otherChanges: this.otherChanges
            }
            try {
                await this.$api.dataCheckup.sendUpdateData(updatedData)
                window.sessionStorage.clear()
                this.$nextTick(() => {
                    this.$router.replace({
                        name: 'DataCheckupSent'
                    })
                })
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('dataCheckup.cannotSendData'))
            }
        }
    }
}
</script>
